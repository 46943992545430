import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleFindCustomerActive = (email, callback) => {

    async function asyncCall() {

        const response = await new Promise(resolve => {
            http.get(
                "public/customers/active",
                {
                    email: email
                },
                (err, res) => {
                    err
                        ? resolve(err)
                        : resolve({
                            state: "success",
                            id: res.data
                        })
                }
            )
        });

        return response.state === "success"
    }

    return asyncCall()

}
