import React, {useEffect, useState} from 'react';
import {getQueryParam} from "../infrastructure/getQueryParam";
import {useLocation} from "@reach/router"
import {navigate} from "gatsby";
import {ThemeProvider} from "styled-components";
import {AppWrapper, GlobalStyle} from "../containers/App/app.style";
import {ContentWrapper} from "../containers/Ride/ride.style";
import {ResetCSS} from "../common/assets/css/style";
import Seo from "../components/seo";
import {GradientWrapper} from "../containers/AppMinimal/app-minimal.style";
import {theme} from 'common/theme/appminimal';
import {handleFindCustomerActive} from "../usecases/customer/findCustomerActive";

const ThankYouPageOld = () => {

    const location = useLocation();

    const checkCustomer = async (grant) => {
        const decoded = atob(grant);
        const parsed = JSON.parse(decoded);
        const interval = setInterval(async () => {
            if (await handleFindCustomerActive(parsed.email)) {
                clearInterval(interval);
                navigate(process.env.APP_HOST + "/login?grant=" + grant);
            }
        }, 1000);


    }

    useEffect(() => {
        const grant = getQueryParam("grant", location);
        if (!grant || grant === '') {
            navigate('/');
        }
        checkCustomer(grant);
        if (!process.env.ENVIRONMENT && process.env.ENVIRONMENT !== "dev") {
            if (typeof window !== "undefined") {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-10780509276/bSpRCKT49aUYENyIxpQo',
                    'transaction_id': ''
                });
            }
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <>
                <Seo title="Thankyou"/>
                {/* end of head */}

                <ResetCSS/>
                <GlobalStyle/>
                {/* end of global and reset style */}

                {/* start app minimal landing */}
                <AppWrapper>
                    <ContentWrapper>
                        <section style={{background: "#1089ff", width: "100%", height: "100px"}}></section>
                        <section style={{width: "50%", margin: "auto", textAlign: "center", paddingTop: "80px"}}>
                            <h1>We are creating your dashboard...</h1>
                            <p>You will be redirected very soon</p>
                            <img src="/img/Spinner-1s-200px.gif" width="200"/>
                        </section>
                    </ContentWrapper>
                    <GradientWrapper>
                        <div style={{'height': '100px'}}></div>
                    </GradientWrapper>
                </AppWrapper>
                {/* end of app minimal landing */}
            </>
        </ThemeProvider>
    );
};
export default ThankYouPageOld;
